import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false
        },
    },
});
