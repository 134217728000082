import { API_URL, getRequest, postRequest, putRequest } from '../helpers/api';
const getAchievements = () => getRequest(`${API_URL}/achievements/available`);
const getFilteredAchievements = (filterData) => postRequest(`${API_URL}/achievements/filtered`, filterData);
const getAdminAchievementsList = () => getRequest(`${API_URL}/admin/achievements/list`, true);
function updateAdminAchievement(achievement_id, description, type_id) {
    return putRequest(`${API_URL}/admin/achievements`, { achievement_id, description, type_id }, true);
}
const getAdminFilteredAchievements = (filterData) => postRequest(`${API_URL}/admin/achievements/filtered`, filterData, true);
const changeAchievementsStates = (achievementsData) => {
    return putRequest(`${API_URL}/admin/achievements/statuses`, achievementsData, true);
};
export { getAchievements, getAdminAchievementsList, updateAdminAchievement, getAdminFilteredAchievements, getFilteredAchievements, changeAchievementsStates, };
