import { getAdminAchievementsList, updateAdminAchievement } from '@/assets/ts/api/achievement';
import AdminPanelLoader from '@/components/panel/panel-components/AdminPanelLoader.vue';
import AdminPanelEditAchievement from './panel-achievement-edit/AdminPanelEditAchievement.vue';
import { defineComponent, onMounted, ref } from 'vue';
import Modal from '@/components/layout/modal/Modal.vue';
export default defineComponent({
    components: {
        Modal,
        AdminPanelLoader,
        AdminPanelEditAchievement
    },
    setup() {
        const isLoading = ref(false);
        const achievements = ref([]);
        const modalText = ref('Osiągnięcia zapisane');
        const modalMessage = ref('');
        const modalRef = ref();
        onMounted(() => getAchievements());
        const getAchievements = () => {
            isLoading.value = true;
            getAdminAchievementsList().then((achievementsIncome) => {
                achievements.value = achievementsIncome.achievements;
            }).finally(() => (isLoading.value = false));
        };
        const onUpdateAchievement = (event) => {
            updateAdminAchievement(event.achievementId, event.description, event.typeId).then(() => {
                modalMessage.value = 'Pomyślnie zmieniono osiągnięcie.';
            }).catch((error) => {
                console.error(error);
                modalText.value = 'Błąd przy edycji';
                modalRef.value.toggleModal();
            }).finally(() => {
                getAchievements();
                modalRef.value.toggleModal();
            });
        };
        return {
            isLoading,
            achievements,
            modalRef,
            modalMessage,
            modalText,
            onUpdateAchievement
        };
    },
});
