import { defineComponent, ref } from 'vue';
export default defineComponent({
    props: {
        achievementId: {
            type: Number,
            required: true,
        },
        typeId: {
            type: Number,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
    emits: ['update-achievement'],
    setup(props, context) {
        const editMode = ref(false);
        const editDescription = ref(props.description);
        const newDescription = ref('');
        const editClick = () => {
            editMode.value = !editMode.value;
        };
        const confirmEdit = () => context.emit('update-achievement', {
            achievementId: props.achievementId,
            description: newDescription.value,
            typeId: props.typeId
        });
        return {
            editClick,
            confirmEdit,
            editDescription,
            editMode,
            newDescription,
        };
    },
});
