import { defineComponent } from 'vue';
import { generateCustomId } from '@/assets/ts/helpers/random';
export default defineComponent({
    props: {
        header: {
            type: String,
            required: true,
        },
        isConfirmationModal: {
            type: Boolean,
            required: false,
            default: false,
        },
        labelConfirmation: {
            type: String,
            required: true,
        },
        labelCancel: {
            type: String,
            required: false,
            default: '',
        },
        closeOnExecute: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['cancel-action', 'execute-action'],
    setup(props, context) {
        const randomId = generateCustomId();
        function toggleModal() {
            const toggleModal = document.querySelector(`#customModalToggler${randomId}`);
            if (toggleModal) {
                toggleModal.click();
            }
        }
        const execute = ($event) => {
            $event.stopPropagation();
            props.closeOnExecute && toggleModal();
            context.emit('execute-action');
        };
        function cancel() {
            context.emit('cancel-action');
        }
        return {
            cancel,
            execute,
            randomId,
            toggleModal
        };
    },
});
